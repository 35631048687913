
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<form @submit.prevent="updatePurchases" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_date" type="date" :label="$store.getters.language.data.purchases.purchase_date" dense class="mx-1" filled required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select outlined class="mx-1" clearable :items="accounts" v-model="purchases.account_id" dense filled item-text="account_name" item-value="account_id" :return-object="false" :label="$store.getters.language.data.accounts.account_name">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select outlined class="mx-1" clearable :items="users" v-model="purchases.user_id" dense filled item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-autocomplete outlined class="mx-1" clearable :items="items" v-model="purchases.item_id" dense filled item-text="item_name" item-value="item_id" :return-object="false" :label="$store.getters.language.data.items.item_name">
						</v-autocomplete>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_price" type="text" :label="$store.getters.language.data.purchases.purchase_price" dense class="mx-1" filled required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_qty" type="text" :label="$store.getters.language.data.purchases.purchase_qty" dense class="mx-1" filled required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_expire_date" type="date" :label="$store.getters.language.data.purchases.purchase_expire_date" dense class="mx-1" filled required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field outlined v-model="purchases.purchase_note" type="textarea" :label="$store.getters.language.data.purchases.purchase_note" dense class="mx-1" filled>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" x-large type="submit">{{$store.getters.language.data.purchases.update_btn}}</v-btn>
					</v-flex>

				</v-layout>
			</form>
		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/purchases.request.js'
	export default {
		data() {
			return {
				purchases: {},
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
			}
		},
		computed: {

			users() {
				return this.$store.getters.users_list
			},

			accounts() {
				return this.$store.getters.accounts_list
			},

			items() {
				return this.$store.getters.items_list
			},

		},
		mounted() {
			this.id = this.$route.params.id
			this.getOnePurchases()
		},
		methods: {
			getOnePurchases() {
				this.loading = true
				requests.getOnePurchases(this.id).then(r => {
					if (r.status == 200) {
						this.purchases = r.data.row
						this.purchases.purchase_date = new Date(r.data.row.purchase_date).toISOString().split('T')[0];
						this.purchases.purchase_expire_date = r.data.row.purchase_expire_date ? new Date(r.data.row.purchase_expire_date).toISOString().split('T')[0] : null
						this.loading = false

					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Purchases',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Purchases',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updatePurchases() {
				this.loading = true
				requests.updatePurchases(this.id, this.purchases).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'Purchases Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    